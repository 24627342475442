html body {
  background-color: #141414;
  color: white;
  font-family: "Din Pro CM";
  font-size: 1.3rem;
}

/* FONTS */

@font-face {
  font-family: "Din Pro CM";
  src: url("../fonts/dinpro-condmedium.otf") format("opentype");
}

@font-face {
  font-family: "Din Pro CB";
  src: url("../fonts/dinpro-condbold.otf") format("opentype");
}

@font-face {
  font-family: "Pink Blue";
  src: url("../fonts/pink-blue.ttf") format("truetype");
}

/* HEADINGS */

h1 {
  font-family: "Pink Blue";
  font-size: 4rem!important;
}

h1.team-member-name {
  font-size: 2.5rem!important;
  color: #CD2924;
  margin-top: 10px;
}

/* GENERAL */

a {
  color: #FFF!important;
  text-decoration: none!important;
}

a:hover { color: #CD2924!important; }

/* HEADER */

header .nav ul.bm-pages li {
  font-family: "Din Pro CB";
  font-size: 20px;
  margin: 0 10px;
}

header .nav ul li a {
  color: #FFF;
  display: block; 
  text-decoration: none;
  padding: 8px 0;
}

header .nav ul li a:hover, header .nav ul li a:active, header .nav ul li a.active { color: #CD2924!important; }

@media screen and (min-width: 992px) {

  header .nav ul.bm-pages li { margin: 0 20px; }

  header .nav ul.bm-sn { margin-left: 20px; }

}

header .nav ul.bm-sn li a img {
  border: none;
}

header .nav ul li a.disabled { color: #AAA!important; }


/* Social Networks */

header div.sn-icon {
  width: 24px;
  height: 24px;
  margin-top: 2px;
}

header div.twitter-icon { background:transparent url('../assets/sn-twitter-24.png') center top no-repeat; }
header div.twitter-icon:hover { background:transparent url('../assets/sn-twitter-red-24.png') center top no-repeat; }

header div.discord-icon { background:transparent url('../assets/sn-discord-24.png') center top no-repeat; }
header div.discord-icon:hover { background:transparent url('../assets/sn-discord-red-24.png') center top no-repeat; }

header div.opensea-icon { background:transparent url('../assets/sn-opensea-24.png') center top no-repeat; }
header div.opensea-icon:hover { background:transparent url('../assets/sn-opensea-red-24.png') center top no-repeat; }

/* HOME PAGE */

div#banner {
  background: url('../assets/background.jpg') center center no-repeat;
  height: 100vh;
}

@media screen and (min-width: 2000px) { div#banner { height: 75vh; } }

@media screen and (max-width: 576px) {
  div#banner {
    height: 50vh;
    background-size: cover;
  }

}

/* Team */

div.the-team {
  background: url('../assets/griffe.png') center center;
  width: 320px;
  height: 320px;
  margin: 0 auto; 
}

div.team-member-bg {
    width: 300px;
    height: 320px;
    border-radius: 50%;
    border: 4px solid #B12723;
    -webkit-transition: all 0.6s ease-in-out;
    -moz-transition: all 0.6s ease-in-out;
    -o-transition: all 0.6s ease-in-out;
    -ms-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
    filter:grayscale(100%);
    background-image: url('../assets/team-members.jpg');
    background-position-x: 0;
}

@media screen and (max-width: 576px) { div.team-member-bg { filter:grayscale(0%); } }

div.team-member-bg:hover { filter:grayscale(0%); background-image: url('../assets/team-members-alt.jpg'); }

div.tm-1 { background-position-x: 0; }
div.tm-2 { background-position-x: -320px; }
div.tm-3 { background-position-x: -640px; }
div.tm-4 { background-position-x: -960px; }
div.tm-5 { background-position-x: -1280px; }
div.tm-6 { background-position-x: -1600px; }

/* Bear Mentality */

div.eyes {
  background: url('../assets/background-eyes.png') top center no-repeat;
  background-size: 85vw;
  padding-bottom: 50px;
}

@media screen and (min-width: 2000px) {
  div.eyes {
    background-size: 70vw;
  }
}

div.spec {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  font-family: "Din Pro CB";
  text-align: center;
  font-size: 2rem;
  padding: 20px;
  background: url('../assets/spec-griffe.png') top center no-repeat, #090909;
  background-size: 75px;
  background-position: 25px;
}

@media screen and (max-width: 576px) {
  div.eyes { background-size: 50vh; padding: 0!important; }
  div.spec {
    font-size: 1.2rem;
    background-size: 50px;
    background-position: 10px;
  }  
}

/* BADGES */

div.badges {
  background: url('../assets/background-badges.jpg') top center no-repeat;
  background-size: 60vw;
  padding-bottom: 100px;
  text-shadow: #141414 1px 0 10px;
}

@media screen and (min-width: 2000px) {
  div.badges { background-size: 45vw; padding-bottom: 200px; }
}

div.badges h1 { color: #CD2924; }

div.badges h2 {
  font-family: "Din Pro CB";
  font-size: 90px;
  line-height: 80px;
  letter-spacing: 3px;
}

@media screen and (max-width: 576px) { div.badges h2 { font-size: 60px; } }


@media screen and (max-width: 576px) {
  div.badges { background-size: 65vh; padding: 0!important; }
}

/* MINT PAGE */

h1.minted-counter {
  font-size: 3rem!important;
  color: #CD2924;
}

.red-button {
  border: 1px solid #141414;
  border-radius: 30px;
  min-width: 320px;
  color: white;
  cursor: pointer;
  font-family: "Pink Blue";
  font-size: 2rem!important;
  background: linear-gradient(to bottom, rgba(222,14,9,1) 0%, rgba(128,25,23,1) 100%);
  transition: 0.5s;
}

.red-button:hover { font-size: 2.1rem!important; }

.lt { font-size: 1.5rem!important; }
.lt:hover { font-size: 1.6rem!important; }

p.connect-wallet {
  color: #999;
  font-style: italic;
  letter-spacing: 1px;
}

p.contract-address, p.client-address {
  color: #999;
  font-style: italic;
  letter-spacing: 1px;
  word-break: break-word;
}

@media screen and (max-width: 576px) { p.contract-address, p.client-address { font-size: 1.1rem; } }

p.error { color: #CD2924; }

div.mint-bear-bg {
  width: 320px;
  height: 320px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

div.mint-bear-1 { background-image: url('../assets/mb-1.jpg'); }
div.mint-bear-2 { background-image: url('../assets/mb-2.jpg'); }
div.mint-bear-3 { background-image: url('../assets/mb-3.jpg'); }

div.mint-box {
  position: relative;
  width: 320px;
  height: 320px;
  margin: 30px auto 0 auto;
  border-radius: 30px;
  border: 2px solid #000;
  transition: background-image 2s ease-in-out;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.mint-box .red-button {
  position: absolute;
  left: 0;
  bottom: 0;
  border: 0;
  border-radius: 0;
  border-top: 1px solid black;
}

.small-red-button {
  width: 35px;
  background-color: #CD2924;
  color: white;
  border: 2px solid #000;
  border-radius: 30%;

}

div.mb-step-0 { background-image: url('../assets/mint-step-0.jpg'); }
div.mb-step-1 { background-image: url('../assets/mint-step-1.jpg'); }
div.mb-step-2 { background-image: url('../assets/mint-step-2.jpg'); }
div.mb-step-3 { background-image: url('../assets/mint-step-3.jpg'); }

div.mb-step-1, div.mb-step-1 .red-button { cursor: wait; }
div.mb-step-2, div.mb-step-2 .red-button { cursor: wait; }
div.mb-step-3, div.mb-step-3 .red-button { cursor: default; }

div.mb-sold-out { background-image: url('../assets/mint-sold-out.jpg'); cursor: not-allowed; }
div.mb-sold-out { background-image: url('../assets/mint-sold-out.jpg'); cursor: not-allowed; }

div.mb-error, div.mb-error .red-button { cursor: not-allowed; }

/* FOOTER */

footer {
  padding-top: 75px;
  min-height: 316px;
  background-image: url('../assets/background-footer.png');
  background-size: cover;
  text-shadow: #141414 1px 0 10px;
}

footer h3 {
  font-size: 40px;
  text-transform: uppercase;
}

/* Social Networks */

footer div.sn-icon {
  width: 64px;
  height: 64px;
  margin: 0 20px; 
}

footer div.twitter-icon { background:transparent url('../assets/sn-twitter-64.png') center top no-repeat; }
footer div.twitter-icon:hover { background:transparent url('../assets/sn-twitter-black-64.png') center top no-repeat; }

footer div.discord-icon { background:transparent url('../assets/sn-discord-64.png') center top no-repeat; }
footer div.discord-icon:hover { background:transparent url('../assets/sn-discord-black-64.png') center top no-repeat; }

footer div.opensea-icon { background:transparent url('../assets/sn-opensea-64.png') center top no-repeat; }
footer div.opensea-icon:hover { background:transparent url('../assets/sn-opensea-black-64.png') center top no-repeat; }

/* TEMP */

/*.opensea-icon { display: none; }*/